import React from "react"
import { graphql } from "gatsby"

import { Row } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap-grid.min.css'

import SecondLayout from "../components/secondlayout"


const PrivacyTemplate = ({ data, pageContext: { lang, currentPath } }) => console.log('') || (

  <SecondLayout wpLang={lang} currentPath={currentPath} pageTitle={data.wordpressPage.yoast_title} pageMeta={data.wordpressPage.yoast_meta} featuredImagePath="above-min.png">
    <Row className="single-page">
    <div className="entry-content">
    <h1>DagCoin Grow Privacy Policy</h1>
    <p>
        DagCoin Grow collects personal data of clients in accordance with this privacy policy. DagCoin Grow encourages Client’s to read this privacy policy carefully. Clients should use Dagcoin grow services only if they consent to the
        privacy policy set forth below.
    </p>
    <h4>1. DagCoin grow collects and processes personal data of:</h4>
    <ol>
        <li>Clients who proceed to use DagCoin grow services.</li>
    </ol>
    <h4>2. The types of information DagCoin Grow may collect are as follows:</h4>
    <ol>
        <li>Contact information, such as Client’s name, mailing address, telephone number, and email address.</li>
        <li>Cryptographich information such as Dagocin wallet address.</li>
        <li>Biographic or demographic information such as Client’s date of birth, gender, Country and any other information Client choose to provide about Client.</li>
        <li>If DagCoin Grow bans or suspends Client from use of the service, a record of that ban or suspension.</li>
        <li>
            Technical information, including the Internet protocol (IP) address used to connect Client’s computer to the Internet, Client’s login information, browser type and version, time zone setting, browser plug-in types and versions,
            operating system and platform.
        </li>
        <li>Details of the transactions Client carries out when using DagCoin Grow, including geographic location from which the transaction originates.</li>
        <li>Information DagCoin Grow receives from other sources.</li>
    </ol>
    <h4>3. DagCoin Grow may use Client’s information as follows:</h4>
    <ol>
        <li>Provide DagCoin Grow Services and customer support.</li>
        <li>Process transactions and send notices about Client’s transactions.</li>
        <li>Resolve disputes, collect payments, and troubleshoot problems.</li>
        <li>Prevent potentially prohibited or illegal activities and enforce DagCoin Grow’s user agreements.</li>
        <li>Personalize, measure and improve DagCoin Grow Services.</li>
        <li>Deliver marketing and promotional offers on behalf of DagCoin Grow via email.</li>
        <li>Link or combine Client’s personal information with information DagCoin Grow obtains from others to help understand Client’s needs and provide Client with better service.</li>
        <li>
            Carry out any other purpose for which the information was collected, to the extent such purpose is necessarily contemplated by the collection of such information or as otherwise notified in the DagCoin Grow Services at the time
            of collection.
        </li>
    </ol>
    <h4>4. The method of collecting personal data:</h4>
    <ol>
        <li>DagCoin Grow collects data on the names, date of birth, country, mailing address and e-mail addresses of DagCoin Grow Clients during the process of registering for the Dagcoin Grow’s services.</li>
        <li>
            DagCoin Grow may receive information about Client from third parties like business partners; sub-contractors in technical; advertising networks; analytics providers and search information providers to whom client has given
            his/her consent to share such information.
        </li>
    </ol>
    <h4>5. Disclosure of Client’s information:</h4>
    <p>DagCoin Grow may share Client’s information with selected third parties including:</p>
    <ol>
        <li>Affiliates, business partners, suppliers and sub-contractors for the performance of any contract DagCoin grow enters into with them for providing services to Client.</li>
        <li>Analytics and search engine providers that assist us in the improvement and optimisation of DagCoin Grow’s site.</li>
        <li>
            If DagCoin Grow is under a duty to disclose or share Client’s personal data in order to comply with any legal obligation, or in order to enforce or apply DagCoin Grow’s Customer Agreement and other applicable agreements; or to
            protect the rights, property, or safety of DagCoin Grow, DagCoin Grow’s customers, or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection and credit risk
            reduction.
        </li>
        <li>To assist DagCoin Grow in conducting or co-operating in investigations of fraud or other illegal activity where DagCoin grow believes it is reasonable and appropriate to do so.</li>
        <li>To prevent and detect fraud or crime.</li>
    </ol>
    <h4>6. Cookies:</h4>
    <p>DagCoin Grow Services use cookies to distinguish Client from other users. This helps DagCoin Grow to provide Client with a good experience and also allows DagCoin Grow to improve Services.</p>
    <h4>7. Client’s rights:</h4>
    <ol>
        <li>Client has the right to ask DagCoin Grow not to process Client’s personal data for marketing purposes by contacting DagCoin Grow at <a href="mailto:grow@dagcoin.org">grow@dagcoin.org</a></li>
        <li>
            Subject to applicable laws, Client may have the right to access information DagCoin Grow has about the client. Client’s right of access can be exercised in accordance with the relevant data protection legislation. Any access
            request may be subject to a fee to meet DagCoin Grow’s costs in providing Client with details of the information DagCoin Grow holds about Client.
        </li>
    </ol>
    <h4>8. Term of retention of personal data:</h4>
    <p>
        DagCoin Grow will store data for as long as it is necessary to provide products and services to Client and others, including those described above. Information associated with Client’s account will be kept until Client’s account is
        deleted, unless DagCoin Grow no longer needs the data to provide products and services in which case the information may be deleted even before the Client’s account is deleted. Some information may be stored for legal purposes.
    </p>
</div>

    </Row>
  </SecondLayout>
)
export const query = graphql`
  query($lang: String) {
    wordpressPage(
      slug: {eq: "home"}
      wpml_current_locale: {eq: $lang}
    ) {
    acf {
      faq_content
      faq_content_1
      didnt_find_an_answer_go_to_support_page
      faq_title_3
      faq_content_3
      faq_content_9
      faq_content_2
      faq_content_4
      faq_content_5
      faq_content_6
      faq_content_7
      faq_content_8
      faq_title
      faq_title_1
      faq_title_2
      faq_title_4
      faq_title_5
      faq_title_6
      faq_title_7
      faq_title_9
      faq_title_8
      frequently_asked_questions
      grow_tile
      home_desc
      home_title
      how_1_desc
      how_1_title
      how_2_desc
      how_3_desc
      how_2_title
      how_3_title
      how_do_grow
      how_it_works
      how_to_desc
      piority_desc
      piority_title
      sign_up
      start_growing
      start_now
      time_to_start
      time_to_start_1_desc
      time_to_start_1_title
      time_to_start_2_desc
      time_to_start_2_title
      time_to_start_3_desc
      time_to_start_3_title
    }
      title
      yoast_meta {
        name
        content
        property
      }
      yoast_title
        wpml_current_locale
    }
    Videoimg: file(relativePath: { eq: "video-min.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }


    SendAs: file(relativePath: { eq: "mac2.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    GetAs: file(relativePath: { eq: "card2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    play: file(relativePath: { eq: "play-button.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    right: file(relativePath: { eq: "right.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Hand: file(relativePath: { eq: "hand.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    Custom_one: file(relativePath: { eq: "1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Custom_two: file(relativePath: { eq: "2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Custom_three: file(relativePath: { eq: "3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }    
    Custom_four: file(relativePath: { eq: "4.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    arrow_right: file(relativePath: { eq: "right.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }


  }
`
export default PrivacyTemplate